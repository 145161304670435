<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <div>
          <v-row class="justify-end">
            <v-btn icon color="primary" @click="$emit('closeDialog')">
              <v-icon v-text="'close'" />
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-1 px-3">
              <h2 class="d-block mb-4" v-text="$t('project.academy.users.updateTeams')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-autocomplete
                v-model="form.ids"
                :label="$t('common.teams')" outlined dense :items="formatTeams" multiple
              />
            </v-col>
          </v-row>
          <v-card-actions class="justify-center">
            <v-btn color="primary" min-width="12rem" @click="save" v-text="$t('common.save')" />
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import getUserEntityIds from '@/utils/getUserEntityIds'

export default {
  name: 'AddTeamsDialog',
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
    item: { type: Object, required: true },
  },
  data: () => ({ form: { ids: [] }, originalIds: [] }),
  computed: {
    ...mapGetters('academy', ['teams']),
    formatTeams: ({ teams }) => teams.map(team => ({ text: team.fullTeamName, value: team.id })),
  },
  created() {
    this.originalIds = getUserEntityIds(this.item, 'team', 'coach')
    this.form.ids = this.originalIds
  },
  methods: {
    save() {
      const { organizationId, projectId } = this
      const idsToUnsubscribe = this.originalIds.filter(id => !this.form.ids.includes(id))
      const idsToSubscribe = this.form.ids.filter(id => !this.originalIds.includes(id))

      this.runAsync(async () => {
        if (idsToUnsubscribe.length) {
          await this.$store.dispatch('projectUsers/removeTeamsFromCoaches', { organizationId, projectId, userId: this.item.id, teamIds: idsToUnsubscribe })
        }
        if (idsToSubscribe.length) {
          await this.$store.dispatch('projectUsers/addTeamsToCoaches', { organizationId, projectId, userId: this.item.id, teamIds: idsToSubscribe })
        }
      })

      this.$emit('closeDialog')
    },
  },
}
</script>
